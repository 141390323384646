import React from "react";
import { Link } from "gatsby";
import {
  roundedBoxShadowBorderRadius,
  RoundedBoxShadowBox,
} from "./atoms/RoundedBoxShadowBox";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Author } from "./molecules/Author";

export const BlogPostPreview = ({ post }) => {
  const authorImage =
    getImage(post.frontmatter.author.image) || post.frontmatter.author.image;

  const featuredImage =
    getImage(post.frontmatter.featuredimage) || post.frontmatter.featuredimage;
  return (
    <div className="column is-6 p-5" key={post.id}>
      <RoundedBoxShadowBox extraClasses="pl-0 pr-0 pt-0 is-clipped">
        {post.frontmatter.featuredimage ? (
          <Link to={post.fields.slug}>
            <div
              className="featured-thumbnail pl-0 pr-0 has-text-centered"
              style={{
                minHeight: 200,
                backgroundColor: featuredImage.backgroundColor,
              }}
            >
              <GatsbyImage
                image={featuredImage}
                imgStyle={{
                  borderTopLeftRadius: roundedBoxShadowBorderRadius,
                  borderTopRightRadius: roundedBoxShadowBorderRadius,
                }}
              />
            </div>
          </Link>
        ) : null}
        <div className="section pl-6 pr-6 pt-4 pb-0">
          <Link to={post.fields.slug}>
            <div
              className="card-header-title pl-0 pr-0 blog-list-item-title"
              style={{
                minHeight: "6rem",
              }}
            >
              <h1 className="title is-size-5-mobile is-size-4-tablet is-size-3-widescreen">
                {post.frontmatter.title}
              </h1>
            </div>
          </Link>
          <p className="has-text-weight-light is-size-5 pt-3">{post.excerpt}</p>
        </div>
        <div className="section pt-4 pb-4 pl-6 pr-6">
          <Author
            image={authorImage}
            subTitle={post.frontmatter.date}
            name={post.frontmatter.author.name}
          />
        </div>
      </RoundedBoxShadowBox>
    </div>
  );
};
