import * as React from "react";

import Layout from "../../components/Layout";
import BlogRoll from "../../components/BlogRoll";
import { TextBanner } from "../../components/molecules/TextBanner";
import { LayoutBox } from "../../components/atoms/LayoutBox";

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout title="Blog" description="Blogs from the neumind team.">
        <TextBanner title="Blog" />
        <LayoutBox>
          <BlogRoll />
        </LayoutBox>
      </Layout>
    );
  }
}
